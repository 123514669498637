.All-invoices {

    padding: 30px 25px !important;

    .Page-title {
        font-size: 25px !important;
        margin-bottom: 26px;
        font-weight: 600;
        display: table;
    }

    .No-invoices-label {
        text-align: center;
        padding-top: 15px;
        border-bottom: 1px solid #CCC;
        padding-bottom: 15px;
    }

    .Button-grid-approvals {
        padding-left: 15px;

        .Approve-button {
            margin-right: 10px;
            text-transform: none;
            padding-left: 10px;
            padding-right: 10px;
        }

        .Reject-button {
            text-transform: none;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .Button-grid-netsuite {
        margin-bottom: 15px;
        padding-right: 15px;
        text-align: end;
        .Netsuite-button {
            text-transform: none;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .Head-label {
        padding-top: 15px !important;
        padding-bottom: 15px !important;

        .MuiTableSortLabel-icon {
            color: white !important;
        }
    }

    .Table-sort-label {
        font-weight: bold;
        color: white !important;
    }

    .Table-unsortable-label{
        font-weight: bold;
        color: white !important;
    }

    .Table-grid {
        display: inline;
    }

    .Details-grid {
        padding-left: 10px;
        display: inline;
    }

    .Table-sort-label {
        font-weight: bold;
        color: white !important;
        text-align: center;
    }

    .Table-row:hover {
        background-color: #efefef !important;
    }

    .Table-cell {
        padding: 12px 9px !important;
        border-right: 1px solid #ccc !important;
        position: relative;

        .Status-chip {
            padding: 2px 10px !important;
            font-weight: 600 !important;
            border-radius: 25px !important;
            text-align: center !important;
            font-size: 12px;
        }

        .Info-icon {
            color: gray;
            cursor: pointer;
            font-size: 8px;
            position: absolute;
            padding-left: 5px;
        }
    }

    .Table-cell-invoice_number {
        max-width: 170px;
        padding-right: 15px !important;
    }

    .Table-cell-job_number {
        max-width: 170px;
        padding-right: 15px !important;
    }

    .Table-cell-amount {
        text-align: end;
    }

    .Table-cell-hr-approval {
        text-align: center;
        min-width: 140px;
    }

    .Table-cell-finance-approval {
        text-align: center;
        min-width: 140px;
    }

    .Table-cell-net-suite-status {
        text-align: center;
    }

    .Table-search-field {
        margin: 10px 15px 10px 15px !important;
    }

    .Search-and-filters {

        padding: 5px 0px;

        .Search-text-fields {
            width: 30%;
        }

        .Filter-button {
            text-transform: none;
            margin-left: 30px;
        }

        .Filters-div {
            padding: 10px 5px 15px 5px;

            .Filter-grid-item-hr-approval {
                padding: 0 10px;
            }
        }
    }

    .Table-container {
        zoom: 90% !important;
    }
}